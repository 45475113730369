body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #dfdfdf;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Eina03-Regular";
  src: local("Eina03-Regular"),
    url("./fonts/Eina03Regular/font.woff2") format("woff2");
  font-weight: normal;
}

@font-face {
  font-family: "Eina03-Bold";
  src: local("Eina03-Bold"),
    url("./fonts/Eina03Bold/font.woff2") format("woff2");
  font-weight: normal;
}
